import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import { FaLongArrowAltRight } from 'react-icons/fa';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import Navigation from '../components/navigation';
import Container from '../components/container';
import SEO from '../components/seo';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('280px', '470px')};
`;

const Article = styled(Container)`
	max-width: 700px;
	line-height: ${between('28px', '30px')};

	* + * {
		margin-top: 23px;
	}

	p {
		font-size: ${between('17px', '18px')};

		& + h2 {
			margin-top: 46px;
		}
	}

	h2 {
		font-size: ${between('26px', '30px')};
		line-height: ${between('26px', '40px')};
	}

	h3 {
		font-size: ${between('22px', '24px')};
		line-height: ${between('28px', '36px')};
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Playfair Display', serif;
		font-weight: 700;

		& + * {
			margin-top: -10px;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	ul,
	ol {
		padding-left: 20px;
		margin-bottom: 35px;

		li {
			margin-top: 10px;
		}
	}

	ul {
		list-style: none;

		li::before {
			content: '◆';
			color: ${props => props.theme.colors.golden_400};
			display: inline-block;
			width: 20px;
			margin-left: -20px;
		}
	}

	blockquote {
		margin-left: 0;
		margin-right: 0;
		padding: 23px;
		border-top: solid 2px;
		border-bottom: solid 2px;
		border-color: ${props => props.theme.colors.golden_400};
		text-align: center;
		font-family: 'Playfair Display', serif;
		font-weight: 700;
	}
`;

const Aside = styled.aside`
	margin-bottom: 66px;

	img {
		width: 75px;
		height: 75px;
		border-radius: 50%;
		float: left;
		margin-right: 16px;
	}

	strong {
		display: block;
		padding-top: 5px;
	}

	time {
		margin-top: 0;
		font-size: 14px;
	}
`;

const AdditionalSection = styled.section`
	background: ${props => props.theme.colors.warm_grey_100};
	position: relative;
	padding-top: ${between('15px', '80px')};
	padding-bottom: ${between('30px', '40px')};
	margin-top: 56px;
`;

const Center = styled(Container)`
	text-align: center;
	margin-bottom: ${between('10px', '60px')};
`;

const BlogPostPage = ({ data, pageContext }) => {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;

	return (
		<Page>
			<SEO title={frontmatter.title} description={frontmatter.seo_description} image={frontmatter.photo} canonical={pageContext.canonical} />
			<StyledHeaderSection hero={frontmatter.photo}>
				<HeaderFront>
					<Navigation />
					<Container>
						<HeaderHeading>{frontmatter.title}</HeaderHeading>
						<HeaderDescription>{frontmatter.categories.join(', ')}</HeaderDescription>
					</Container>
				</HeaderFront>
			</StyledHeaderSection>

			<Article>
				<Aside>
					<img src={frontmatter.author_avatar} alt="Avatar" />
					<strong>{frontmatter.author_name}</strong>
					<time>{frontmatter.date}</time>
				</Aside>

				<div dangerouslySetInnerHTML={{ __html: html }} />
			</Article>

			<AdditionalSection className="slant--top slant--bottom">
				<Center>
					<h2>Prowadzisz usługi weselne?</h2>
					<Link to="/dodaj-oferte/">
						Dodaj ofertę <FaLongArrowAltRight />
					</Link>
				</Center>
			</AdditionalSection>
		</Page>
	);
};

export default BlogPostPage;

export const query = graphql`
	query BlogPostPage($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			frontmatter {
				date(formatString: "DD MMMM YYYY", locale: "pl")
				slug
				title
				categories
				photo
				seo_description
				author_name
				author_avatar
			}
		}
	}
`;
